.completeBlockLinksTemplate {

    .full-width.bg-light {

        background-image: url("../images/thematic_sites.webp") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        filter: sepia(0.5);
        border-top: 1px solid $my-darkblue !important;
        border-bottom: 1px solid $my-darkblue !important;

        .card-bg {
            opacity: 0.9;
            background-color: $my-lightblue !important;
            border: 1px solid $my-blue !important;

            h3 {
                color: $my-blue !important;
                .icon {
                    color: $my-blue !important;
                    fill: $my-blue !important;
                }
            }

            a:hover {

                h3 {
                    text-decoration: underline !important;
                }
                
            }

            &:hover {

                filter: brightness(85%) !important;
                
            }
            
        }

        .row {

            h2 {
                text-align: center !important;
                color: $my-darkblue !important;
                padding-bottom: 1.5rem !important;
            }
            
        }

    }

} 

/*
.completeBlockLinksTemplate {

    .full-width.bg-light {

        background-color: $my-lightyellow !important;
        border: 1px solid $my-darkblue !important;
        //border-top: 1px solid $my-darkblue !important;
        //border-bottom: 1px solid $my-darkblue !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .card-bg {

            background-color: $my-yellow !important;
            border: 1px solid $my-darkblue !important;

            h3 {
                color: $my-darkblue !important;
                .icon {
                    color: $my-darkblue !important;
                    fill: $my-darkblue !important;
                }
            }

            a:hover {

                h3 {
                    text-decoration: underline !important;
                }
                
            }

            &:hover {

                filter: brightness(85%) !important;
                
            }
            
        }

        .row {

            h2 {
                text-align: center !important;
                color: $my-darkblue !important;
                padding-bottom: 1.5rem !important;
            }
            
        }

    }

} */