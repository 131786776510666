@media print {
  body {
    display: block !important;
  }

  .public-ui {
    // removed underlining from links
    a {
      font-weight: 600 !important;
      text-decoration: none !important;
    }

    .content-area,
    .public-ui {
      page-break-after: avoid;
      page-break-before: avoid;
      page-break-inside: avoid;
    }

    // removed part of header header
    .it-header-slim-wrapper {
      display: none;
    }

    .it-header-navbar-wrapper {
      display: none;
    }

    .it-header-center-wrapper {
      background: none;

      .it-header-center-content-wrapper {
        .it-brand-wrapper {
          padding: 0;
          margin: 0 auto;

          // incresed name of municipalitie
          a {
            .icon {
              fill: $link-color;
            }

            .it-brand-text {
              .no_toc {
                color: $link-color;
                font-size: 2rem !important;
              }
            }
          }
        }

        .it-right-zone {
          display: none;
        }
      }
    }

    /* Removed link list in PageHeader*/
    .PageHeaderWrapper {
      page-break-after: avoid;
      page-break-before: avoid;
      page-break-inside: avoid;

      .link-list-wrapper {
        display: none;
      }
    }
    /* Removed max-height from accordion block*/
    .block.accordion {
      display: flex !important;

      .accordion-item,
      .accordion-content,
      .accordion-inner {
        padding: 0 10px;
      }
    }

    /* Removed shadow and card's padding. Add border*/
    .card {
      border: 1px solid $gray-border !important;
      page-break-inside: avoid;

      &.shadow {
        box-shadow: none !important;
      }

      &.bigborder {
        border: none !important;
        border-left-width: 0 !important;
      }
    }

    //card teaser 3
    .card-wrapper.card-teaser-wrapper.card-teaser-block-3 {
      > .card-teaser {
        flex: 0 0 32%;
      }
    }

    #text-tempi_e_scadenze > div > p {
      padding: 1rem 0 0 0 !important;
      box-shadow: none !important;
    }

    #briciole {
      display: none;
    }

    // removed first column in CT pages
    #view .page-header-right {
      display: none;
    }

    #view > div {
      .row.border-top.row-column-border.row-column-menu-left {
        border-top: none !important;
      }

      // Reduced max width image header
      .content-image {
        width: 100%;
        max-width: 100%;

        .row.row-full-width {
          margin-right: 0;
          margin-left: 0;
        }

        figure {
          display: flex;
          justify-content: center;

          img {
            max-width: 300px !important;

            &.full-width {
              position: initial;
              right: unset;
              left: unset;
              width: auto !important;
              height: auto;
              max-height: 300px;
              margin: 0 !important;
              object-fit: unset;
            }
          }
        }
      }

      aside {
        display: none;
      }

      .it-page-sections-container {
        border-top: none;

        .it-carousel-wrapper {
          display: none;
        }

        .video {
          display: none;
        }

        /*.block { //i blocchi devono essere visibili per quei CT che hanno i blocchi
          display: none;
        }*/
      }
    }

    #geocoded-result {
      .leaflet-control-attribution {
        display: none;
      }
    }

    .leaflet-container {
      break-inside: avoid;
    }

    #contenuti-correlati {
      display: none;
    }

    #correlato-in-evidenza {
      display: none;
    }

    .scroll-to-top {
      display: none;
    }

    footer.it-footer {
      display: none;
    }

    .feedback-form {
      display: none;
    }

    .grecaptcha-badge {
      display: none;
    }

    .gdpr-privacy-show-banner {
      visibility: hidden;
    }
  }

  //subsite footer
  .subsite-footer {
    display: none;
  }

  //text editor styles
  .public-ui {
    p.callout-bg,
    p.callout {
      padding: 0rem 1.25rem;
      border: none;
      margin: 0.5rem 0rem;
      box-shadow: none;
      font-size: 1rem;
    }
  }

  .draft-text-larger {
    font-size: 1.2rem;
  }

  .public-ui .draftjs-buttons a {
    padding: 0rem;
    background-color: white;
    color: $link-color;
  }

  //external link icon
  svg.external-link {
    display: none;
  }
}
