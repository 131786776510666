.cardWithImageTemplate {

    .full-width.bg-light {
        //background-image: url("../images/card_with_image_template_slim.png") !important;
        background-image: url("../images/calendar_bg.webp") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-attachment: fixed !important;
        background-size: cover !important;
        //filter: invert(92%) sepia(90%) saturate(522%) hue-rotate(319deg) brightness(99%) contrast(105%);

        .row {
            text-align: center !important;
            color: $my-white !important;
        }

        a.btn {
            //background-color: rgba(255,255,255,0.7) !important;
            background-color: $my-white !important;
            color: $my-blue !important;
            border: 1px solid $my-blue !important;
            //box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6) !important;
        }

        a.btn:hover {
            background-color: $my-blue !important;
            color: $my-white !important;
            text-decoration: underline;
          }

        .title-bottom-line {
            border-bottom-color: $my-white !important;
        }

        /*.items {
            background-color: rgba(255,255,255,0.7) !important;
        }*/

    }
       
}