.public-ui {
  .card {
    .card-body {
      .card-text {
        line-height: 1.5em;
      }
    }
  }
}

.card.border-left-card {
  display: flex;
  flex-wrap: nowrap;
  border-left: 8px solid $primary-a0;
  box-shadow: 0px 4px 4px 0px #00000026;

  .card-title {
    a {
      display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
    }
  }

  &.card-small {
    @include rem-size(line-height, 24);

    h5.card-title,
    .text.fw-bold {
      a {
        @include rem-size(font-size, 18);
        text-decoration: none;
        text-transform: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    p.card-text {
      @include rem-size(font-size, 16);
    }
  }

  &.card-big-io-comune {
    .card-title,
    .text.fw-bold {
      color: $link-color;
      font-weight: 600;

      a {
        @include rem-size(font-size, 24);
        @include rem-size(line-height, 32);
        text-decoration: none;
        text-transform: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    p.card-text {
      @include rem-size(font-size, 18);
      @include rem-size(line-height, 24);
    }
  }

  &.card-teaser .card-body {
    flex: 1 0 74%;
  }
}

.card-big-io-comune {
  &.card.card-teaser {
    display: flex;
  }

  .card-body {
    padding: 0em !important;

    .category-top {
      .category {
        text-decoration: none;
      }
    }

    .card-title {
      margin-bottom: 0.3em !important;
      font-weight: 600;

      a {
        display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
      }

      a,
      &.venue-card-title.venue-card-title {
        @include rem-size(font-size, 24);
        @include rem-size(line-height, 32);
        font-weight: 600;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .card-text {
      @include rem-size(font-size, 18);
      @include rem-size(line-height, 24);
      font-family: 'Titillium Web' !important;
    }
  }
}

.card-small {
  .card-body {
    h5.card-title {
      a {
        @include rem-size(font-size, 18);
        display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
        text-decoration: none;
        text-transform: none;
      }
    }

    p.card-text {
      @include rem-size(font-size, 16);
    }
  }
}

.card.preview-image-card {
  .image-container {
    flex-basis: 20%;
    margin: auto;

    img {
      max-width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

#documenti-collegati .genericcard {
  .card-body {
    h5.card-title {
      a {
        @include rem-size(font-size, 18);
        @include rem-size(line-height, 24);
        display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
        font-weight: 600;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      svg {
        fill: $link-color;
      }
    }
  }
}

.block.listing.simpleCard {
  @include media-breakpoint-down(xl) {
    .card {
      row-gap: 0.56em;
    }

    .card-body {
      flex-basis: 100%;
      margin-left: 0em;
    }
  }
}

.card-body {
  .card-link.pdc {
    font-size: 0.89rem;
  }
}

.custom-calendar-card {
  .card-calendar.extended {
    width: 100px;

    .card-date .date-label {
      @include rem-size(font-size, 16);
      font-weight: 600;

      + .date {
        @include rem-size(font-size, 20);
      }
    }
  }

  .card-calendar {
    .card-date {
      .date {
        font-family: $font-family-sans-serif;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.4rem;

        &.day {
          @include rem-size(font-size, 28);
          @include rem-size(line-height, 28);
        }

        &.month {
          font-weight: 600;
          text-transform: capitalize;
          @include rem-size(font-size, 16);
          @include rem-size(line-height, 21.1);
        }
      }
    }
  }
}
