.contenttype-servizio {
  #deadlines {
    .calendar-vertical {
      .calendar-date-day {
        span.title-xxlarge-regular {
          font-family: 'Roboto Mono', monospace;

          .calendar-date-description {
            .calendar-date-description-content {
              color: $body-color;
            }
          }

          .calendar-date-description:before {
            width: 0px;
            height: 0%;
          }
        }
      }
    }
  }

  #submit-request {
    //margin between card "Accedi al servizio"
    .card.border-left-card {
      margin-bottom: 1.2em;
    }
  }

  #altri_documenti_items {
    .genericcard {
      h5.card-title {
        svg {
          fill: $link-color;
          @include margin-size(margin-right, 20);
        }

        a {
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
}
