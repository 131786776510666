.it-header-navbar-wrapper {

    background-color: $my-lightblue !important;

    span {
            color: $my-blue !important;
        }

    .menu-wrapper {
        a:hover {
            text-decoration: underline !important;
            text-decoration-color: $my-blue !important;
            }
        
    }

    svg {
        color: $my-blue !important;
        fill: $my-blue !important;
  
        &:hover {
          filter: brightness(85%) !important;
        }
  
  
    }
  
}


@media (min-width: 992px) {

    .it-header-navbar-wrapper {
        border-bottom: 1px solid $my-blue !important;

        .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
            border-color: $my-blue !important;
        }

    }

}

@media (max-width: 991px) {

    .it-nav-wrapper {
        border-bottom: 1px solid $my-blue !important;
    }

}