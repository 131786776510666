@media (min-width: 992px) {

  .it-nav-wrapper {

    //border-top: 3px solid $my-white !important;
    border-top: 1px solid $my-blue !important; //new

    .it-header-center-wrapper {
        background-color: $my-lightblue !important;
        

        .it-header-center-content-wrapper {

            h2, span  {
                color: $my-blue !important;
            }

            .it-brand-wrapper a img {
              content: url("../images/logo_lignano.svg");
          }
          
      }

      .it-right-zone {


        .it-socials {
          ul {
            .icon {
              color: $my-blue !important;
              fill: $my-blue !important;

              &:hover {
                filter: brightness(85%) !important;
              }
              
            }
          }
        }

        a.rounded-icon {
          background-color: $my-lightblue !important;
          color: $my-blue !important;
          fill: $my-blue !important;
          outline: $my-blue 2px solid !important;

          &:hover {
            //background-color: red !important;
            filter: brightness(85%) !important;
          }


        }

      }

    }

  }


}


@media (max-width: 991px) {

  .it-header-center-wrapper {
    background-color: $my-lightblue !important;
    //border-top: 3px solid $my-white !important;

    border-top: 1px solid $my-blue !important; //new

    .it-header-center-content-wrapper {

      h2, span  {
          color: $my-blue !important;
      }

      .it-brand-wrapper a img {
        content: url("../images/logo_lignano.svg");
        //z-index: 4 !important;
        margin-left: 2em !important;
      }
    
    }

  }

  .it-right-zone {


    .it-socials {
      ul {
        .icon {
          color: $my-blue !important;
          fill: $my-blue !important;

          &:hover {
            filter: brightness(85%) !important;
          }
          
        }
      }
    }

    a.search-link svg {
      background-color: $my-lightblue !important;
      //color: $my-blue !important;
      fill: $my-blue !important;
      
      //outline: $header-center-bg-color 2px solid !important;

      &:hover {
        //background-color: red !important;
        filter: brightness(85%) !important;
      }


    }

  }

}





