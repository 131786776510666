//mobile
.it-header-center-wrapper {
  // height: $dvt-header-center-max-height;

  .it-header-center-content-wrapper {
    //right zone
    .it-right-zone {
      .it-socials {
        ul {
          .icon {
            //outline: $header-center-bg-color 2px solid !important;
            color: $primary-text;
            //color: $header-center-bg-color;
            //fill: $header-center-bg-color;
          }
        }
      }

      .it-search-wrapper {
        a.search-link {
          //color: $primary-text;
          //outline: $header-center-bg-color 2px solid !important; //edit
          //fill: $header-center-bg-color;
          &:focus {
            box-shadow: 0 0 0 5px $focus-outline-color !important;
          }
        }
      }
    }
  }

  @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg') - 1px) {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        .header-logo {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .it-right-zone .it-search-wrapper a.rounded-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
  }
}
