.argumentInEvidence {

    .argumentInEvidence-background {
        background-color: $my-white !important;
        //background-color: $my-darkyellow !important;

        
    }

    .container.argumentsCardsWrapper  {



        h2 {

            color: $my-black !important;
            //text-decoration: underline !important;

        }

        .grid {

            //border-top: 1px solid black !important;
            margin-top: 10px !important;
            padding-top: 26px !important;
            

            .icon-argument-container {
                background-color: $my-yellow !important;
                border: 1px solid $my-headerblue !important;

                .icon {
                    color: $my-headerblue !important;
                    fill: $my-headerblue !important;
                }
        
            }

        }

        //non deploiato
        /*.btn-primary {

            background-color: $my-yellow !important;
            border: 1px solid $my-darkblue !important;
            color: $my-darkblue !important;

            &:hover {
                filter: brightness(85%) !important;
            }

        }*/
        
    }

}