/*@media (min-width: 992px) {

    #correlato-in-evidenza .section {

        border: 20px solid $my-white !important;
        margin-bottom: 20px !important;
    
    }

}


@media (max-width: 991px) {

    #correlato-in-evidenza .section {

        border: 10px solid $my-white !important;
        margin-bottom: 10px !important;
    
    }

}*/

#correlato-in-evidenza .section {

    margin-bottom: 1em !important;

}