#search-modal {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;

  #search-button {
    border-bottom: 1px solid $neutral-1-a7;
    line-height: 2rem;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .search-filters {
    max-width: 700px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }

  .back-to-search {
    .icon {
      margin-right: 0.3em;
    }
  }

  .group-col {
    margin-top: 40px;
  }

  .chip {
    cursor: pointer;
  }
}
