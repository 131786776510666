.it-footer-main {

    background-color: $my-darkblue !important;
    border-top: 1px solid hsla(0deg, 0%, 100%, 0.5) !important;

    .it-brand-wrapper a img {
        content: url("../images/logo_lignano.svg");
    }

}

.it-footer-small-prints {

    background-color: $my-darkblue !important;
    border-top: 1px solid hsla(0deg, 0%, 100%, 0.5);
    
}


